import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { PromanButtonComponent } from '../../button';
import { DialogTitleComponent } from '../../common-components/standalone/dialog-title.component';
import { DialogActionsComponent } from '../../common-components/standalone/dialog-actions.component';
import { TranslatePipe } from '../../shared/pipes/pipes/translate.pipe';

// SIMPLE YES NO MODE DIALOG

@Component({
    selector: 'pro-simple-confirm-dialog',
    standalone: true,
    imports: [
        MatLegacyDialogModule,
        FlexLayoutModule,
        TranslatePipe,
        PromanButtonComponent,
        DialogTitleComponent,
        DialogActionsComponent,
    ],
    template: `
    <pro-dialog-title [title]="data.header || 'action_approval'"></pro-dialog-title>
    <div mat-dialog-content>
        <div>{{ data.question | translate }}?</div>
    </div>
    <pro-dialog-actions [isCallbackHidden]="true" [removeCloseButton]="true" (callback)="confirm()" variant="confirm">
        <pro-btn (onClick)="dialogRef.close(1)"
                 class="DialogCloseButton"
                 [label]="'yes'"></pro-btn>
        <pro-btn (onClick)="dialogRef.close(0)"
                 class="DialogCloseButton"
                 [label]="'no'"></pro-btn>
    </pro-dialog-actions>
  `
})

export class PromanSimpleConfirmDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: {
            header: string,
            question: string,
        },
        public dialogRef: MatLegacyDialogRef<PromanSimpleConfirmDialogComponent>) {
    }

    confirm() {

    }

    set(parameter: any, value: any) {
        parameter.value = value;
    }
}
