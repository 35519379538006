import { Component, Input, OnInit } from '@angular/core';
import { findById, getIndexById, isDefined } from '@proman/utils';
import { Entity } from '@proman/services/entity.service';
import { MaterialEntityInterface } from '@proman/resources/material';
import {
    Article,
    ArticleChild,
    ArticleMaterial,
    MaterialCategory,
} from '@proman/interfaces/entity-interfaces';
import { FilterService } from '@proman/services/filter.service';
import { Money } from '@proman/interfaces/common.interface';
import { ArticlesService } from '../services/articles.service';
import { ACL } from '@proman/services/acl.service';
import { CurrenciesService } from '@proman/services/currencies.service';

@Component({
    selector: 'pm-article-summary-materials',
    template: `
        <div [ngStyle]="{ background: color, padding: '10px', borderRadius: '10px', border: '1px solid white' }">
            <pro-label>
                <div fxLayout="row" fxLayoutAlign="start center" (click)="handleClick()"
                     [ngClass]="{ Clickable: isSubmenu }">
                    @if (!!isSubmenu) {
                        <span proClickStopPropagation>
                        <a [routerLink]="'Article' | pmSref: article.id">{{ article.name }}
                            @if (article.weightUnit) {
                                ({{ article.weightUnit }})
                            }
                            : {{ ratio * article.productYield }}</a>
                    </span>
                    } @else {
                        {{ 'materials' | translate }}
                    }

                </div>
                @if (isHidden && totalPrice) {
                    <div fxFlex></div>
                    <div style="width: 92px; font-size: 14px;">{{ totalPrice | proPrice }}</div>
                }
            </pro-label>
            @if (!isHidden && materialCategories?.length) {
                @for (mc of materialCategories; track $index) {
                    <div class="PositionRelative">

                        <table class="Table Width100">
                            <colgroup>
                                <col />
                                <col width="100px" />
                                <col width="100px" />
                                <col width="100px" />
                                <col width="100px" />
                                <col width="100px" />
                                <col width="100px" />
                            </colgroup>
                            <tr>
                                <th>{{ mc.name }}</th>
                                <th>{{ 'description' | translate }}</th>
                                <th>{{ 'brutto' | translate }}</th>
                                <th>{{ 'netto' | translate }}</th>
                                <th>{{ 'unit' | translate }}</th>
                                <th>{{ 'price' | translate }}</th>
                                <th>{{ 'price_ratio' | translate }}</th>
                            </tr>
                            @for (material of mc.materials; track $index) {
                                <tr>
                                    <td><a
                                      [routerLink]="'Material' | pmSref: material.id: 'material.edit'"> {{ material.name }}</a>
                                    </td>
                                    <td>{{ (material.description || '') | translate }}</td>
                                    <td>{{ (material._quantity || 0.0) | proDecimal: 4 }}</td>
                                    <td>{{ material.netto | proDecimal: 4 }}</td>
                                    <td>{{ material.materialType?.unit }}</td>
                                    <td>{{ material._price | proPrice: 4 }}</td>
                                    <td>{{ material._priceRatio | proDecimal: 4 }}</td>
                                </tr>
                            }
                        </table>

                    </div>
                }
            }

            @if (materialsArticles?.length) {
                <div style="padding-left: 22px;">
                    @for (materialArticle of materialsArticles; track $index) {
                        <pm-article-summary-materials
                          [article]="materialArticle.article"
                          [isSubmenu]="true"
                          [ratio]="materialArticle.quantity * ratio"
                          [color]="materialArticle.article.categories.length ? '#' + materialArticle.article.categories[0].color : ''">
                        </pm-article-summary-materials>
                    }
                </div>
            }

            @if (subproducts?.length) {
                <div style="padding-left: 22px;">
                    @for (sub of subproducts; track $index) {
                        <pm-article-summary-materials
                          [article]="sub.child"
                          [isSubmenu]="true"
                          [ratio]="sub.quantity * (ratio * (sub.coefficientBruttoNetto || 1))"
                          [color]="sub.child.categories.length ? '#' + sub.child.categories[0].color : ''">
                        </pm-article-summary-materials>
                    }
                </div>
            }

        </div>

    `,
    styles: [`
        th {
            text-align: left;
            font-size: 0.9em;
            font-weight: normal;
        }

        .ArticleSummaryRightOverlay {
            position: absolute;
            top: 0;
            right: 0;
        }

        .AdjustUnits {
            top: -15px;
            position: relative;
        }
    `]
})

export class ArticleSummaryMaterialsComponent implements OnInit {
    @Input() article: Article;
    @Input() isSubmenu: boolean;
    @Input() color: any;
    @Input() ratio: number = 1;
    isHidden: boolean;

    totalPrice: Money;
    totalQuantity: number;
    materialEntity: MaterialEntityInterface;
    materialCategories: any[];
    subproducts: any[] = [];
    materialsArticles: any[] = [];
    isOther: boolean = false;

    constructor(
        private Currencies: CurrenciesService,
        private Entity: Entity,
        private Filter: FilterService,
        private Articles: ArticlesService,
        private ACL: ACL,
    ) {
        this.materialEntity = this.Entity.get('material') as MaterialEntityInterface;

        this.totalPrice = this.Currencies.getZeroPrice();
        this.totalQuantity = 0;
    }

    ngOnInit() {
        if (this.ACL.check('material.view')) {
            this.materialEntity
                .search({
                    'articleMaterials.article.id': this.article.id,
                    'join': [
                        'categories',
                        'articleMaterials',
                        'articleMaterials.article',
                        'categories.parent',
                        'materialType',
                        'defaultQuant'
                    ]
                })
                .then(async (data: any[]) => {
                    const rootCategories = [];
                    let localRoot;
                    let root;
                    const otherCat = { id: -1, name: this.Filter.translate('other') } as MaterialCategory;

                    if (!data) return; // prevent 500 response error crash frontend;

                    for (const material of data) {
                        if (!(material.categories[0])) {
                            material.categories = [otherCat];
                            this.isOther = true;
                        }

                        localRoot = !material.categories[0].parent ? material.categories[0] : material.categories[0].parent;
                        root = findById(rootCategories, localRoot);

                        if (!isDefined(root)) {
                            rootCategories.push(localRoot);
                            if (!localRoot.materials) localRoot.materials = [];
                            root = localRoot;
                        }

                        root.materials.push(material);
                    }

                    // move 'other' category to end of array
                    if (this.isOther && rootCategories.length) {
                        const index = getIndexById(rootCategories, 0);
                        rootCategories.push(rootCategories.splice(index, 1)[0]);
                    }

                    await this.Entity.get<ArticleChild>('article_child')
                        .search({ 'parent.id': this.article.id, 'join': ['child', 'child.categories'] })
                        .then((response) => this.subproducts = response.filter((a) => !a.optional));

                    await this.Entity.get('article_material')
                        .search({ 'article.id' : this.article.id, 'join': ['material', 'material.defaultQuant', 'material.materialType'] })
                        .then((response: ArticleMaterial[]) => {
                            let totalPrice: number = 0;
                            let totalNettoQuantity: number = 0;
                            let totalBruttoQuantity: number = 0;

                            response.forEach((articleMaterial) => {

                                for (const material of data) {
                                    if (articleMaterial.material && articleMaterial.material.id === material.id) {
                                        material._quantity = articleMaterial.standardQuantity * this.ratio || 0;
                                        material._price = this.Currencies.getZeroPrice();
                                        let cost = 0;
                                        let netto = 0;
                                        if (articleMaterial.standardCost > 0) {
                                            cost = articleMaterial.standardCost;
                                        } else if (material.standardCost > 0) {
                                            cost = material.standardCost;
                                        } else {
                                            cost = +material.defaultQuant?.unitPrice?.amount;
                                        }

                                        netto = material._quantity * (+material.ratioBruttoNetto || 1) * (material.articleMaterials[material.articleMaterials.length - 1].article.id === this.article.id ?
                                            material.articleMaterials[material.articleMaterials.length - 1].finalCoefficient : 1);

                                        const price = material._quantity * cost;
                                        totalPrice += price;
                                        const priceRatio = netto * cost;
                                        material._price.amount = price.toString();
                                        material._priceRatio = priceRatio.toString();
                                        material.netto = netto.toString();
                                        totalNettoQuantity += ((material._quantity || 0.0) * (material.ratioBruttoNetto || 1));
                                        totalBruttoQuantity += material._quantity;
                                        material.description = articleMaterial.description;
                                    }
                                }
                            });

                            this.Articles.addMaterials(data.filter((item) => !!item._price));
                        });

                    this.materialCategories = rootCategories;
                });

        }
    }

    handleClick = () => {
        if (this.isSubmenu) this.isHidden = !this.isHidden;
    };
}
